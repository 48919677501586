import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [{
	path: '/',
	name: '首页',
	redirect: '/dingdan'
}, {
	path: '/login',
	name: 'login',
	component: () => import('views/login/login.vue')
}, {
	path: '/dingdan',
	name: '订单',
	component: () => import('views/dingdan/dingdan.vue')
}, {
	path: '/shangpin',
	name: '商品',
	component: () => import('views/shangpin/shangpin.vue')
}, {
	path: '/pinpai',
	name: '品牌',
	component: () => import('views/pinpai/pinpai.vue')
}, {
	path: '/jituan',
	name: '集团',
	component: () => import('views/jituan/jituan.vue')
}, {
	path: '/youhuiquan',
	name: '优惠券',
	component: () => import('views/youhuiquan/youhuiquan.vue')
}, { 
	path: '/huiyuan',
	name: '会员',
	component: () => import('views/huiyuan/huiyuan.vue')
}, { 
	path: '/news',
	name: '新闻管理',
	component: () => import('views/news/news.vue')
}, { 
	path: '/yangpin',
	name: '样品订单管理',
	component: () => import('views/yangpin/yangpin.vue')
} ]

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})